import 'htmx.org';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import './alpine-spar-autofill.js';
import '../styles.css';
import './init.js';

Alpine.plugin(collapse)
Alpine.store('slideOver', {
    isOpen: false,
    isSynced: true,

    close() {
        this.isOpen = false;
    },

    open() {
        this.isOpen = true;
    }
})

window.Alpine = Alpine;
Alpine.start();

document.body.addEventListener('htmx:afterSwap', (event) => {
    // DEBUG: Uncomment the following line to see the event object
    // console.log('HTMX content swapped:', event.target);
});

window.dispatch = function (event) {
    window.dispatchEvent(new CustomEvent(event));
}

window.addEventListener('open-slideover', () => {
    document.body.classList.add('no-scroll');
    Alpine.store('slideOver').open();
});

window.addEventListener('close-slideover', () => {
    Alpine.store('slideOver').close();
    document.body.classList.remove('no-scroll');
    document.getElementById('slideover-content').innerHTML = '';
});

window.addEventListener('close-modal', () => {
    document.getElementById('modal-content').innerHTML = '';
});

window.chatComponent = function (ceremonyId, currentUser, currentUserId) {
    return {
        messages: [],
        ws: null,
        chatContainer: null,
        chatInputField: null,
        today: new Date(),
        init() {
            this.chatContainer = document.getElementById('chat-messages');
            this.chatInputField = document.getElementById('chat-message-input');
            // Connect to the WebSocket with wss protocol if the page is loaded over HTTPS
            const wsProtocol = window.location.protocol === 'https:' ? 'wss' : 'ws';
            const wsUrl = `${wsProtocol}://${window.location.host}/ws/ceremony/${ceremonyId}/`;
            this.ws = new WebSocket(wsUrl);
            this.ws.onopen = () => {
                this.chatInputField.focus();
            }
            this.ws.onmessage = (event) => {
                const data = JSON.parse(event.data);
                if (data.type === 'history') {
                    const history = data.messages;
                    history.forEach((msg) => {
                        msg.isMine = (msg.created_by.id === currentUserId);
                        const date = new Date(msg.created_at);
                        const locale = navigator.language || 'sv-SE';
                        if (date.toDateString() === this.today.toDateString()) {
                            msg.timestamp = date.toLocaleTimeString(locale, {hour: 'numeric', minute: 'numeric'});
                        } else {
                            msg.timestamp = date.toLocaleDateString(
                                locale, {month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'}
                            );
                        }
                    });
                    this.messages = history;
                    this.scrollToBottom();
                    return;
                }

                data.isMine = (data.created_by.id === currentUserId);
                const date = new Date(data.created_at);
                const locale = navigator.language || 'sv-SE';
                if (date.toDateString() === this.today.toDateString()) {
                    data.timestamp = date.toLocaleTimeString(locale, {hour: 'numeric', minute: 'numeric'});
                } else {
                    data.timestamp = date.toLocaleDateString(
                        locale, {month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric'}
                    );
                }
                this.messages.push(data);
                this.scrollToBottom();
            };
            this.ws.onclose = () => {
                // TODO: handle close
            };
            this.ws.onerror = (error) => {
                // TODO: handle error
            };
        },
        sendMessage() {
            const message = this.chatInputField.value.trim();
            if (message) {
                this.ws.send(JSON.stringify({message}));
                this.chatInputField.value = '';
                this.scrollToBottom();
            }
        },
        scrollToBottom() {
            // small delay to allow the browser to update the scroll height properly
            setTimeout(() => {
                this.chatContainer.scrollTop = this.chatContainer.scrollHeight;
            }, 50);
        },
        autoResize() {
            this.chatInputField.style.height = 'auto';
            this.chatInputField.style.height = this.chatInputField.scrollHeight + 'px';
        }
    }
}
