document.addEventListener("alpine:init", () => {
    Alpine.data("sparAutofill", (url, fieldMappings) => ({
        ssn: "",
        lastFetchedSsn: null,
        error: null,
        init() {
            // Clear all fields on initialization
            for (const fieldId of Object.values(fieldMappings)) {
                const field = document.getElementById(fieldId);
                if (field) {
                    field.value = "";
                }
            }
        },
        fetchData() {
            if (this.ssn === this.lastFetchedSsn) {
                return;
            }
            this.lastFetchedSsn = this.ssn;

            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "X-CSRFToken": document.querySelector('[name=csrfmiddlewaretoken]').value,
                },
                body: new URLSearchParams({ssn: this.ssn}),
            })
                .then((response) => {
                    if (!response.ok) {
                        throw new Error("No hits found");
                    }
                    return response.json();
                })
                .then((data) => {
                    if (data.error) {
                        this.error = data.error;
                        return;
                    }

                    for (const [fieldName, fieldId] of Object.entries(fieldMappings)) {
                        if (fieldName === "ssn") continue; // skip ssn so we don't overwrite it
                        const field = document.getElementById(fieldId);
                        if (field) {
                            field.value = data[fieldName] || "";
                        }
                    }
                })
                .catch((error) => {
                    this.error = "Failed to fetch data.";
                    console.error("Fetch Error:", error);
                });
        },
    }));
});
